.layout-footer {
    transition: margin-left $transitionDuration;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    border-top: 1px solid var(--surface-border);
    position: fixed;
    bottom: 10px;
    width:100%;
    z-index: 102;
}



.layout-footer-menu {
    display: inline;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding-top: 1rem;
    border-top: 1px solid var(--surface-border);
    bottom: 10px;
    z-index: 102;
}