/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";


.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 0.25rem;
}

.p-tabview-panels {
    padding: 0.25rem;
}

.invoice-badge {
    border-radius: var(--border-radius);
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.invoice-badge {
    &.status-MAILED {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-CREATED {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-APPROVED {
        background: #FEEDAF;
        color: #8A5340;
    }

    &.status-new {
        background: #B3E5FC;
        color: #23547B;
    }

    &.status-renewal {
        background: #ECCFFF;
        color: #694382;
    }

    &.status-proposal {
        background: #FFD8B2;
        color: #805B36;
    }
}

.loading-container {
    display: flex;
    position: fixed;
    align-items: center;
    align-content: center;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    z-index: 1000;
  }

  :host ::ng-deep .custom-spinner .p-progress-spinner-circle {
    animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-progress-spinner-color {
    100%,
    0% {
        stroke: #16697A;
    }
    40% {
        stroke: #489FB5;
    }
    66% {
        stroke: #82C0CC;
    }
    80%,
    90% {
        stroke: #FFA62B;
    }
}

@keyframes custom-progress-spinner-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}